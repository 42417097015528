/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-24 10:20:13
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-12 17:29:43
 */
import {request} from "./request";

// 购液企业列表
export function getPurchaseListRequest(params) {
  return request({
    url: '/trade/trade/emption_enterprise/pageByEnterpriseManagement',
    method: 'post',
    data:params
  })
}

// 添加购液企业信息
export function addPurchaseRequest(params) {
  return request ({
    url: '/trade/trade/emption_enterprise',
    method : 'post',
    data: params
  })
}

// 修改购液企业信息
export function editPurchaseRequest(params) {
  return request ({
    url: `/trade/trade/emption_enterprise/one/${params.stationId}`,
    method : 'put',
    data: params
  })
}

// 关闭购液企业信息
export function clearPurchaseRequest(params) {
  return request ({
    url: `/trade/trade/emption_enterprise/one/${params.stationId}`,
    method : 'delete',
  })
}

// 液厂企业列表
export function getManufacturerListRequest(params) {
  return request ({
    url: 'trade/factory/page',
    method : 'post',
    data: params
  })
}

// 添加液厂企业
export function addManufacturerRequest(params) {
  return request ({
    url: '/trade/factory',
    method : 'post',
    data: params
  })
}

// 修改液厂企业
export function editManufacturerRequest(params) {
  return request ({
    url: `/trade/factory/${params.factoryId}`,
    method : 'put',
    data: params
  })
}

// 关闭液厂企业
export function clearManufacturerRequest(params) {
  return request ({
    url: `/trade/factory/${params.factoryId}`,
    method : 'delete',
    data: params
  })
}

// 运输公司列表
export function getTransportRequest(params) {
  return request ({
    url: '/trade/transportation/list',
    params
  })
}

// 添加运输企业
export function addTransportRequest(params) {
  return request ({
    url: '/trade/transportation',
    method : 'post',
    data: params
  })
}

// 修改运输企业
export function editTransportRequest(params) {
  return request ({
    url: '/trade/transportation',
    method : 'put',
    data: params
  })
}

// 删除运输企业
export function clearTransportRequest(params) {
  return request ({
    url: '/trade/transportation',
    method : 'put',
    data: params
  })
}