<template>
    <div style="height: 100%">
        <!-- 卡片视图 -->
        <el-card style="height: 100%;overflow:auto;">
            <!-- 顶部面包屑导航 -->
            <breadcrumb-nav>
                <template v-slot:firstMenu>计划管理</template>
                <template v-slot:secondMenu>计划明细</template>
            </breadcrumb-nav>
            <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
                计划明细
            </div>
            <div class="radioBox">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="选择计划日期">
                        <el-date-picker
                                v-model="formInline.time"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy/MM/dd"
                                @change="onSubmit"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="购液企业">
                        <el-select v-model="formInline.region1" clearable placeholder="请选择购液企业"
                                   @change="onSubmit">
                            <el-option
                                    v-for="item in enterpriseList"
                                    :key="item.stationId"
                                    :label="item.stationName"
                                    :value="item.stationId"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="液源">
                        <el-select v-model="formInline.region" clearable placeholder="请选择液源" @change="onSubmit">
                            <el-option
                                    v-for="item in sourceList"
                                    :key="item.sourceId"
                                    :label="item.sourceName"
                                    :value="item.sourceName"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="配送方式">
                        <el-select v-model="formInline.region2" clearable placeholder="请选择配送方式"
                                   @change="onSubmit">
                            <el-option label="自提" value="1"></el-option>
                            <el-option label="配送" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批结果">
                        <el-select v-model="formInline.region3" clearable placeholder="请选择审批结果"
                                   @change="onSubmit">
                            <el-option label="通过" value="3"></el-option>
                            <!-- <el-option label="审核中" value="2"></el-option> -->
                            <el-option label="拒绝" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="buttonBox">
                <el-button type="primary" @click="handleExportPlan()">导出</el-button>
            </div>
            <!-- table表格区域 -->
            <plan-details-table :plan-list='goodsList'/>
            <div style="float: right;">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pageNum"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="queryInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import BreadcrumbNav from "../common/BreadcrumbNav";

import {getPlanDetailsListRequest} from "network/plan";
import {getPurchaseListRequest} from 'network/customer';
import {getGoodsListRequest} from 'network/goods';
import PlanDetailsTable from './PlanDetails/PlanDetailsTable.vue';
import {excelPlan} from "@/network/plan";

export default {
    name: "List",
    components: {
        BreadcrumbNav,
        PlanDetailsTable
    },
    data() {
        return {
            queryInfo: {
                // query: "",
                pageNum: 1,
                pageSize: 10
            },
            formInline: {
                time: '',
                region1: '',
                region: '',
                region3: '',
                region2: ''
            },
            goodsList: [],
            enterpriseList: [],
            sourceList: [],
            total: 0,
            queryTime: {},
        }
    },
    created() {
        this.getGoodsList();
        this.getEnterpriseList();
        this.getSourceList()
    },
    methods: {
        onSubmit() {
            if (this.formInline.user) {
                this.queryTime.startDate = this.formInline.time[0]
                this.queryTime.endDate = this.formInline.time[1]
            } else {
                this.queryTime = {}
            }
            this.getGoodsList()
        },
        getEnterpriseList() {
            getPurchaseListRequest({
                page: {
                    pageNo: '1',
                    pageSize: 100000
                }
            }).then(res => {
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage('获取数据失败', 'error');
                }
                this.enterpriseList = result.data.list;
            })
        },
        getSourceList() {
            getGoodsListRequest({
                pageNumber: 1,
                pageSize: 100000,
                tardId: localStorage.getItem('enterpriseId')
            }).then(res => {
                console.log(res)
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage('获取数据失败', 'error');
                }
                this.sourceList = result.data.list
            })
        },
        getGoodsList() {
            let params = {
                ...this.queryInfo,
                tardId: localStorage.getItem('enterpriseId'),
                enterpriseId: this.formInline.region1,
                sourceName: this.formInline.region,
                tardCheckStatus: this.formInline.region3,
                distributionType: this.formInline.region2,
                ...this.queryTime
            }
            if(this.formInline.time){
                params.startDate=this.formInline.time[0]
                params.endDate=this.formInline.time[1]
            }
            getPlanDetailsListRequest(params).then(res => {
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage('获取计划数据失败', 'error');
                }
                this.goodsList = result.data.list;
                this.total = result.data.total;
            });
        },
        //计划明细导出
        handleExportPlan() {
            let params = {
                ...this.queryInfo,
                tardId: localStorage.getItem('enterpriseId'),
                enterpriseId: this.formInline.region1,
                sourceName: this.formInline.region,
                tardCheckStatus: this.formInline.region3,
                distributionType: this.formInline.region2,
                ...this.queryTime
            }
            if(this.formInline.time){
                params.startDate=this.formInline.time[0]
                params.endDate=this.formInline.time[1]
            }
            excelPlan(params).then(res => {
                this.downloadCallback(res, '计划明细.xlsx');
            });
        },
        // 每页显示的数据条数发送变化
        handleSizeChange(newSize) {
          this.queryInfo.pageNum = 1
            this.queryInfo.pageSize = newSize;
            this.getGoodsList();
        },

        // 当前页码发生变化
        handleCurrentChange(newPage) {
            this.queryInfo.pageNum = newPage;
            this.getGoodsList();
        },

        // 跳转到添加商品的界面
        goAddPage() {
            this.$router.push('/add');
        },
        downloadCallback(res, fileName) {
            const content = res.data;
            const blob = new Blob([content]);
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        },
    }
}
</script>

<style scoped>

.radioBox {
    margin-top: 30px;
    margin-bottom: 15px;
}

.buttonBox {
    display: flex;
}

.el-button {
    background-color: var(--theme_bg_color);
    border: 1px solid var(--theme_bg_color)
}


</style>
