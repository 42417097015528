<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-02-27 15:54:08
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-08 09:42:08
-->
<template>
  <el-table highlight-current-row :data="planList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="planDate" label="计划日期" width="120px"></el-table-column>
    <el-table-column prop="planNumber" label="订单号" width="120px"></el-table-column>
    <el-table-column prop="enterpriseName" label="购液企业名称" width="120px"></el-table-column>
    <el-table-column prop="distributionTypeName" label="配送方式" width="120px"></el-table-column>
    <el-table-column prop="tractorNumber" label="车辆信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.tractorNumber?scope.row.tractorNumber:"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="配送地" width="120px">
      <template slot-scope="scope">
        {{ scope.row.distributionType==1?scope.row.enterpriseName:scope.row.address }}
       </template>
    </el-table-column>
    <el-table-column prop="driverInfo" label="司机信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.driverInfo?scope.row.driverInfo:"--"}}</span>
        <!-- <span>{{ str.replace(new RegExp("a","g"),"") }}</span> -->
      </template>
    </el-table-column>
    <el-table-column prop="escortInfo" label="押送员信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.escortInfo?scope.row.escortInfo:"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="arrivalDate" label="期望到站时间" width="120px"></el-table-column>
    <el-table-column prop="sourceName" label="液源" width="120px"></el-table-column>
    <el-table-column prop="listingPrice" label="挂牌价(元/吨)" width="120px">
       <template slot-scope="scope">
        {{ scope.row.listingPrice?scope.row.listingPrice:'--' }}
       </template>
    </el-table-column>
    <el-table-column prop="weight" label="预估总质量(吨)" width="120px"></el-table-column>
    <el-table-column prop="estimationMoney" label="预估总金额(元)" width="120px"></el-table-column>
    <el-table-column prop="createUser" label="提交人" width="120px" ></el-table-column>
    <el-table-column prop="planAuditor" label="审批人" width="120px" ></el-table-column>
    <el-table-column prop="checkStatusName" label="审批结果" width="120px" ></el-table-column>
    <el-table-column prop="auditDate" label="审批时间" width="120px" ></el-table-column>
  </el-table>
  
</template>

<script>
// import PlanEdit from './PlanEdit.vue';

  export default {
    name: "PlanTable",
    props: {
      planList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        // PlanEdit
      
    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>

</style>
